<template>
  <v-card-text>
    <v-card>
      <v-card-text>
        <engagements-table
          :included="included"
          :engagements="engagements"
          :engagementsCount="engagementsCount"
          :isLoading="isLoading"
          @updateEngagements="handleEngagementsUpdate"
        >
        </engagements-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import EngagementsTable from "@admin/components/EngagementsTable";

export default {
  name: "EngagementsIndex",
  components: {
    EngagementsTable,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState("engagements", ["engagements", "engagementsCount", "included"]),
    ...mapGetters(["acceptRoles"]),
    showEngagementAddButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
  },
  beforeDestroy() {
    this.clearEngagements();
  },
  methods: {
    ...mapActions("engagements", ["getEngagements", "clearEngagements"]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    async handleEngagementsUpdate(options) {
      this.isLoading = true;
      await this.getEngagements(options);
      this.isLoading = false;
    },
  },
  async mounted() {
    if (!this.engagements.length) {
      this.isLoading = true;
      await this.getEngagements();
      this.isLoading = false;
    }
    this.setBreadcrumbs();
    this.setTitleObject("Engagements");
    let actionButtons = [];
    if (this.showEngagementAddButton) {
      actionButtons = [
        {
          attributes: {
            to: "/engagements/add",
          },
          component: "AddButton",
          text: "Add Engagement",
          index: 0,
        },
      ];
    }
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearEngagements();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
